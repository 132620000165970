<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Staff information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-3">
            <input type="text" id="txtRegNo" class="form-control" placeholder="Regd No" v-if="member" v-model="member.reg_no" autocomplete="off" autofocus>
          </div>
          <div class="col-md-7">
            <input type="text" id="txtName" class="form-control" placeholder="Staff Name" v-if="member" v-model="member.name" autocomplete="off">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Department:</label>
          <div class="col-md-10">
            <select data-placeholder="Select Department" class="form-control" v-if="member" v-model="member.department_id" >
              <option v-for="dept in departments" v-bind:value="dept.id">
                {{ dept.name }}
              </option>
            </select>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">EMail:</label>
          <div class="col-md-7">
            <input type="email" class="form-control" placeholder="Email" v-if="member" v-model="member.email" autocomplete="off">
          </div>

          <label class="col-md-1 col-form-label">Mobile:</label>
          <div class="col-md-2">
            <input type="tel" class="form-control text-center" placeholder="Mobile" maxlength="10" v-if="member" v-model="member.mobile" autocomplete="off">
          </div>
        </div>

        <div class="text-right">
          <SaveButton  @handle_save_action="perform_save_action"></SaveButton>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'

export default {
  name: 'StaffForm',
  components: { SaveButton },
  data () {
    return {
      member: JSON.parse('{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department_name":"","section_id":1,"section_name":"","subject_id":1,"subject_name":"","academicyear_id":1,"year":0,"email":"","mobile":"","image":""}'),
      departments:[],
      sections:[],
      subjects:[],
    }
  },
  store,
  component: {

  },
  props: {
    mymember: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department_name":"","section_id":1,"section_name":"","subject_id":1,"subject_name":"","academicyear_id":1,"year":0,"email":"","mobile":"","image":""}')
    }
  },
  beforeMount () {
    this.member = this.mymember;
  },
  created () {
    // this.$data.member = JSON.parse('{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department_name":"","section_id":1,"section_name":"","subject_id":1,"subject_name":"","academicyear_id":1,"year":0,"email":"","mobile":"","image":""}');
    // this.$data.member.year = (new Date()).getFullYear();
  },
  mounted () {
    const self = this;

    this.loadDepartments();
    // this.loadSections();
    $('#txtRegNo').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('staff_window_closed');
    },
    loadSubjects () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');

      self.$data.subjects = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.subjects =  resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    },
    loadDepartments () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.departments =  resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    },
    createBase64Image(fileObject) {
      const self = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        self.member.image = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    handleImage(e){
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },
    perform_save_action () {
      const self = this;

      try {

        if(self.$data.member.batch == null ){
          self.$data.member.batch = "";
        }
        self.$data.member.section_id = 1;

        if (self.$data.member.reg_no.toString().length < 4) {
          alert('Invalid Regd No');
          return
        } else if (self.$data.member.name.toString().length < 5) {
          alert('Invalid Name');
          return
        } else if (parseInt(self.$data.member.department_id) < 1) {
          alert('Invalid Department');
          return
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        const requestOptions = {
          method: (self.$data.member.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.member)
        }

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/member`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtRegNo').focus(); }, timer: 1500 });
            self.member = JSON.parse('{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department_name":"","section_id":1,"section_name":"","subject_id":1,"subject_name":"","academicyear_id":1,"year":0,"email":"","mobile":"","image":""}');
            self.$emit('staff_saved', resp.data);
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', onClose: () => { $('#txtRegNo').focus(); }, timer: 3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => { $('#txtRegNo').focus(); }, timer: 3000 });
        }).finally(() => {
          $('#mycard').unblock();
        });
      }catch (e) {
        alert(e);
      }

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00!important;
    color: #0a0a0a!important;
  }
</style>
